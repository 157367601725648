<template>
	<div class="download-app-page">
		<div class="page-top flex-center-between">
			<div class="icon" @click="back">
				<img :src="leftUrl" alt="">
			</div>
			<div class="title">{{$t('download-title8')}}</div>
			<div class="icon"></div>
		</div>

		<div class="download-main">
			<div class="title">
        {{AppName}} {{$t('download-title6')}}
			</div>
			<div class="sub-title">
				{{$t('download-title7')}}
			</div>
			<div class="info">{{$t('download-title1')}} {{AppName}} {{$t('download-title2')}}</div>
			<div class="info">{{AppName}} {{$t('download-title3')}}</div>
			<div class="bottom-img">
				<img :src="bottomImg" alt="">
			</div>

			<div class="flex-center-between btn-box">
				<div class="btn btn1 flex-1" @click="download('android')"></div>
				<div class="btn btn2 flex-1" @click="download('ios')"></div>
			</div>
		</div>
	</div>
</template>
<script>
import { configList, userInfo } from '@/api/user'
export default {
	data() {
		return {
			leftUrl: require('../../assets/imgs/icon-arrow.png'),
			bottomImg: require('../../assets/imgs/icon-download.png'),
			btnImg1: require('../../assets/imgs/download-btn1.png'),
			btnImg2: require('../../assets/imgs/download-btn2.png'),
			iosUrl: '',
			AppName: '',
			androidUrl: '',
			companyList: [
				{
					icon: require('../../assets/imgs/download-company1.png'),
				},
				{
					icon: require('../../assets/imgs/download-company2.png'),
				},
				{
					icon: require('../../assets/imgs/download-company3.png'),
				},
				{
					icon: require('../../assets/imgs/download-company4.png'),
				},
				{
					icon: require('../../assets/imgs/download-company5.png'),
				},
				{
					icon: require('../../assets/imgs/download-company6.png'),
				},
			]
		}
	},
	mounted() {
		this.init()
		// if (this.isMobile !== 1) {
		// 	this.checkMobile()
		// }
	},
	methods: {
		init() {
      configList().then(res => {
				let list = res.data
        for(var i in list){
          if(list[i].Name == 'download_ios'){
            this.iosUrl = list[i].Value
          }
          if(list[i].Name == 'download_android'){
            this.androidUrl = list[i].Value
          }
          if(list[i].Name == 'app_name'){
            this.AppName = list[i].Value
          }
        }
			})
		},
		download(type) {
			let url = type == 'ios' ? this.iosUrl : this.androidUrl
			let a = document.createElement('a')
			// a.href = url
      a.href = url
			a.target = '_blank'
			document.body.appendChild(a)
			a.click()
			document.body.removeChild(a)
		},
		back() {
			this.$router.go(-1)
		}
	}
}
</script>